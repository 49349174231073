import React from "react";
import logo from "../images/mainLogo.svg";
import menu from "../images/icon-hamburger.svg";
import close from "../images/icon-close.svg";

const Navbar = ({menuClick, setMenu}) => {
  return (
    <nav className={menuClick ? "backmeu" : ""}>
      <div className="backContainer">
        <div className="dflex">
          <a href="/" title="oubaida logo" className="logo">
            <img src={logo} alt="oubaida logo" />
          </a>
          <div className="humicon" onClick={() => setMenu(!menuClick)}>
            <img 
              src={menuClick ? close : menu} 
              alt="mobile menu" 
              title="menu" 
            />
          </div>
        </div>

        <div className={menuClick ? "menu" : "menu dismenu"}>
          <a 
            href="#about" 
            title="about" 
            onClick={() => setMenu(false)}
          >
            About
          </a>
          <a 
            href="#experience" 
            title="experience" 
            onClick={() => setMenu(false)}
          >
            Experience
          </a>
          <a 
            href="#projects" 
            title="projects" 
            onClick={() => setMenu(false)}
          >
            Projects
          </a>
          <a 
            href="#skills" 
            title="skills" 
            onClick={() => setMenu(false)}
          >
            Skills
          </a>
          <a 
            href="#contact" 
            title="contact" 
            onClick={() => setMenu(false)}
          >
            Contact
          </a>
          <a 
            className="resumebtn" 
            title="Resume" 
            href="resume.pdf" 
            target="_blank" 
            rel="noopener noreferrer" 
            onClick={() => setMenu(false)}
          >
            Resume
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;