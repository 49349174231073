import React from "react";
import github from "../images/github.svg";
import extLink from "../images/external-link-svgrepo-com.svg";
import desTest from "../images/download.jpg";

const Project = ({Title,Description,Thumbnail,techList,demoLink,githubLink}) => {
  return (
    <div className="Project">
      <div className="ProDetails">
        <h4>{Title}</h4>
        <p className="description">{Description}</p>
        <p className="tags">{techList.map((e,i)=>(e+"  "))}</p>
        {githubLink&&<a href={githubLink} target="_blank">
          <img src={github} alt="github link" />
        </a>}
        <a href={demoLink} target="_blank">
          <img src={extLink} alt="demo link" />
        </a>
      </div>
      <div className="ProjDemo">
        <a  className="imageLink" href={demoLink} target="_blank">
          <img src={Thumbnail} alt="demo image" />
        </a>
        <div className="linkIcon">
        <a  href="#" target="_blank">
          <img src={extLink} alt="demo link" />
        </a>
        </div>
      </div>
    </div>
  );
};

export default Project;
