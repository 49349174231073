import React from 'react'
import About from './About'
import Projects from './Projects'
import Contact from './Contact'
import Skills from './Skills'
import Experience from './Experience'


const Main = () => {
  return (
    <main>
      <About />
      <Experience /> 
      <Projects />
      <Skills />
      <Contact />
    </main>
  )
}

export default Main