const dataSkills = [
    {
        title: "Frontend",
        names: ["React", "Next.js", "Redux", "HTML", "CSS", "Sass", "JavaScript", "Tailwind CSS", "Material UI", "Bootstrap"],
        certi: [
            ["React", "hackerrank", "https://www.hackerrank.com/certificates/11d3e3041608"],
            ["Problem Solving (Basic)", "hackerrank", "https://www.hackerrank.com/certificates/c0685f608056"],
            ["Problem Solving (Intermediate)", "hackerrank", "https://www.hackerrank.com/certificates/28dc6269ea50"],
            ["Javascript(Basic)", "hackerrank", "https://www.hackerrank.com/certificates/c91e2380a3f3"],
            ["Javascript(Intermediate)", "hackerrank", "https://www.hackerrank.com/certificates/b1c376d64064"],
            ["CSS", "hackerrank", "https://www.hackerrank.com/certificates/2a1503aedaa0"]
        ]
    },
    {
        title: "Backend",
        names: ["Node.js", "Express.js", "REST APIs", "C#"],
        certi: []
    },
    {
        title: "Database",
        names: ["MongoDB", "DynamoDB", "MySQL", "SQL Server", "Firebase"],
        certi: [
            ["SQL(Basic)", "hackerrank", "https://www.hackerrank.com/certificates/0967f1aa4c52"]
        ]
    },
    {
        title: "DevOps & Cloud",
        names: ["AWS S3", "EC2", "Lambda", "CloudFront", "Docker", "CI/CD", "GitHub Actions"],
        certi: [
        ]
    },
    {
        title: "Design",
        names: ["Figma", "Photoshop", "Illustrator", "Canva"],
        certi: []
    },
    {
        title: "Other",
        names: ["VS Code", "Google Analytics", "Git", "GitHub", "Bitbucket", "Jira"],
        certi: [["Digital Marketing ID: 8KS A99 K7L", "google", "https://learndigital.withgoogle.com/digitalgarage/validate-certificate-code"]]
    }
];

export default dataSkills;