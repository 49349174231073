import { useEffect, useState } from "react";
import Loading from "./Components/Loading";
import Navbar from "./Components/Navbar";
import Header from "./Components/Header";
import Main from "./Components/Main";
import Footer from "./Components/Footer";
import Socials from "./Components/Socials";

function App() {
  const [loading, setLoading] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showSocials, setShowSocials] = useState(false);
  
  // Handle scroll events
  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    const navbar = document.querySelector(".backContainer");
    
    if (currentScrollY > 100) {
      setShowSocials(true);
    }
    
    if (navbar) {
      if (scrollPosition < currentScrollY) {
        navbar.classList.remove("scrollTrue");
        navbar.classList.add("scrollFalse");
      } else {
        navbar.classList.remove("scrollFalse");
        navbar.classList.add("scrollTrue");
      }
      
      if (currentScrollY === 0) {
        navbar.classList.remove("scrollTrue");
      }
    }
    
    setScrollPosition(currentScrollY);
  };
  
  // Add scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);
  
  // Simulate loading time
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);
    
    return () => clearTimeout(timer);
  }, []);
  
  if (loading) {
    return <Loading />;
  }

  return (
    <div className={!menuOpen ? "App" : "App scrolN"}>
      <div className="firstback">
        <Navbar setMenu={setMenuOpen} menuClick={menuOpen} />
        <Header menuClick={menuOpen} />
      </div>
      <Main />
      {showSocials && <Socials />}
      <Footer />
    </div>
  );
}

export default App;