import React, { useState } from 'react';
import { 
  FaReact, FaHtml5, FaCss3Alt, FaSass, FaNodeJs, FaDatabase, 
  FaAws, FaDocker, FaFigma, FaGithub, FaGitAlt, FaJira, 
  FaSearchengin, FaCode, FaServer
} from 'react-icons/fa';
import { 
  SiNextdotjs, SiRedux, SiJavascript, SiTailwindcss, 
  SiBootstrap, SiExpress, SiCsharp, SiMongodb, SiMysql,
  SiMicrosoftsqlserver, SiFirebase, SiVisualstudiocode, 
  SiGoogleanalytics, SiBitbucket, SiAdobephotoshop, 
  SiAdobeillustrator, SiCanva, SiGithubactions, 
  SiHackerrank, SiGoogle, SiAmazonaws
} from 'react-icons/si';
import { BiCodeCurly } from 'react-icons/bi';
import { GrCertificate } from 'react-icons/gr';
import dataSkills from '../DataSkills';
import '../style/Skills.css';

// Function to get skill icons
const getSkillIcon = (skill) => {
  const iconMap = {
    // Frontend
    'React': <FaReact color="#61DAFB" />,
    'Next.js': <SiNextdotjs color="#000000" />,
    'Redux': <SiRedux color="#764ABC" />,
    'HTML': <FaHtml5 color="#E34F26" />,
    'CSS': <FaCss3Alt color="#1572B6" />,
    'Sass': <FaSass color="#CC6699" />,
    'JavaScript': <SiJavascript color="#F7DF1E" />,
    'Tailwind CSS': <SiTailwindcss color="#06B6D4" />,
    'Material UI': <FaCode color="#007FFF" />,
    'Bootstrap': <SiBootstrap color="#7952B3" />,
    'SEO': <FaSearchengin />,
    
    // Backend
    'Node.js': <FaNodeJs color="#339933" />,
    'Express.js': <SiExpress color="#000000" />,
    'REST APIs': <BiCodeCurly />,
    'C#': <SiCsharp color="#239120" />,
    
    // Database
    'MongoDB': <SiMongodb color="#47A248" />,
    'DynamoDB': <FaDatabase color="#4053D6" />,
    'MySQL': <SiMysql color="#4479A1" />,
    'SQL Server': <SiMicrosoftsqlserver color="#CC2927" />,
    'Firebase': <SiFirebase color="#FFCA28" />,
    
    // DevOps & Cloud
    'AWS S3': <FaAws color="#FF9900" />,
    'EC2': <FaAws color="#FF9900" />,
    'Lambda': <FaAws color="#FF9900" />,
    'CloudFront': <FaAws color="#FF9900" />,
    'Docker': <FaDocker color="#2496ED" />,
    'CI/CD': <FaCode />,
    'GitHub Actions': <SiGithubactions color="#2088FF" />,
    
    // Design
    'Figma': <FaFigma color="#F24E1E" />,
    'Photoshop': <SiAdobephotoshop color="#31A8FF" />,
    'Illustrator': <SiAdobeillustrator color="#FF9A00" />,
    'Canva': <SiCanva color="#00C4CC" />,
    
    // Other
    'VS Code': <SiVisualstudiocode color="#007ACC" />,
    'Google Analytics': <SiGoogleanalytics color="#E37400" />,
    'Git': <FaGitAlt color="#F05032" />,
    'GitHub': <FaGithub color="#181717" />,
    'Bitbucket': <SiBitbucket color="#0052CC" />,
    'Jira': <FaJira color="#0052CC" />
  };
  
  return iconMap[skill] || <FaCode />; // Default icon if not found
};

// Function to get certification icons
const getCertIcon = (provider) => {
  if (provider.includes('hacker')) return <SiHackerrank color="#00EA64" />;
  if (provider.includes('google')) return <SiGoogle color="#4285F4" />;
  if (provider.includes('aws')) return <SiAmazonaws color="#FF9900" />;
  return <GrCertificate />;
};

const Skills = () => {
  const [activeBox, setBox] = useState(3); // Start with DevOps & Cloud (AWS) selected
  const [allSkills] = useState(dataSkills);

  return (
    <div className='skills' id="skills">
      <h3 className="sectionTitle">Skills and Certifications</h3>
      
      
      
      <div className='slider'>
        <div className='sliderControl'>
          {allSkills.map((e, i) => (
            <button 
              key={i} 
              onClick={() => {setBox(i)}} 
              className={activeBox === i ? "active" : ""}
            >
              {e.title}
            </button>
          ))}
        </div>

        {allSkills.map((category, i) => 
          activeBox === i ? (
            <div className='content' key={i}>
              <h4>Technologies:</h4>
              <div className='items'>   
                {category.names.map((skill, ind) => (
                  <span key={ind} className="skill-item">
                    {getSkillIcon(skill)}
                    {skill}
                  </span>
                ))}
              </div>
              
              {category.certi.length ? <h4>Certificates:</h4> : ""}
              <div className='items2'>
                {category.certi.map((cert, certIndex) => (
                  <a 
                    href={cert[2]} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    key={certIndex}
                    className={`cert-item ${cert[0].includes('AWS') ? 'aws-cert-item' : ''}`}
                  >
                    {getCertIcon(cert[1])}
                    {cert[0]}
                  </a>
                ))}
              </div>
            </div>
          ) : null
        )}
      </div>
      {/* Highlight AWS Certification */}
      <div className="aws-certification-highlight">
        <a 
          href="https://www.credly.com/badges/3feaa47e-7f52-42ab-96a1-39cb54fb1758" 
          target="_blank" 
          rel="noopener noreferrer"
          className="aws-cert-link"
        >
          <div className="aws-cert-container">
            <div className="aws-cert-logo">
              <img 
                src="https://images.credly.com/size/340x340/images/b9feab85-1a43-4f6c-99a5-631b88d5461b/image.png"
                alt="AWS Certified Developer - Associate"
                className="aws-cert-badge-img"
              />
            </div>
            <div className="aws-cert-details">
              <h4>AWS Certified Developer - Associate</h4>
              <p>Validated expertise in developing, deploying, and debugging cloud-based applications using AWS</p>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Skills;