import React from "react";

const Header = ({ menuClick }) => {
  return (
    <div className={menuClick ? "container header space" : "container header"}>
      <div className="content">
        <p>Hi, my name is</p>
        <h1>Youssef Oubaida</h1>
        <h2>Full Stack Developer & AWS Certified</h2>
        <p>
          Building scalable web solutions with modern technologies and cloud infrastructure.
        </p>
        <button onClick={() => window.location.href = 'mailto:mr.oubaidayoussef@gmail.com'}>
          Contact Me
        </button>
      </div>
    </div>
  );
};

export default Header;