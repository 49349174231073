import React from "react";
import "../style/Experience.css";

const Experience = () => {
  return (
    <div className="experience container" id="experience">
      <h3 className="sectionTitle">Experience</h3>
      <div className="experienceContainer">
        <div className="experienceItem">
          <div className="experienceHeader">
            <h4>Full Stack Developer</h4>
            <span className="company">Metavers</span>
            <span className="date">02/2024 - Present</span>
          </div>
          <div className="experienceContent">
            <div className="project">
              <h5>Content Creation Platform Enhancement</h5>
              <ul>
                <li>
                  Upgraded a Lexical-based rich text editor with new tools:
                  dividers, countdown timers, and text boxes.
                </li>
                <li>
                  Developed an admin media upload system for uploading,
                  organizing, and managing images for seamless content
                  integration.
                </li>
                <li>
                  Identified and resolved various bugs, improving platform
                  stability and performance.
                </li>
              </ul>
              <div className="techStack">
                <span>Next.js</span>
                <span>Lexical</span>
                <span>DynamoDB</span>
                <span>AWS S3</span>
                <span>CloudFront</span>
                <span>Elasticsearch</span>
                <span>Express</span>
              </div>
            </div>
            <div className="project">
              <h5>Project Management Platform Development</h5>
              <p>
                Led the development of the project (similar to Upwork/Jira),
                building 70% of the platform and implementing core
                functionalities. Developed an admin approval system for skill
                verification, enabling administrators to review, approve, or
                reject user verification requests efficiently.
              </p>
              <div className="techStack">
                <span>Next.js</span>
                <span>Express</span>
                <span>DynamoDB</span>
                <span>AWS S3</span>
                <span>CloudFront</span>
                <span>Docker</span>
                <span>ECR</span>
                <span>ELB</span>
                <span>EKS</span>
                <span>GitHub Actions</span>
              </div>
            </div>
            <div className="project">
              <h5>SaaS Subscription & Access Control System</h5>
              <p>
                Developed a subscription-based access control system for a SaaS
                platform, integrating Stripe for seamless payment processing.
                Built a role-based permissions system, enabling team admins to
                assign specific access rights to team members based on their
                roles.
              </p>
              <div className="techStack">
                <span>React.js</span>
                <span>Express</span>
                <span>MongoDB</span>
                <span>Stripe</span>
              </div>
            </div>
            <div className="project">
              <h5>Intelligent Call Center Workflow</h5>
              <p>
                Designed and implemented a dynamic call-routing system using
                Amazon Connect, ensuring efficient call distribution based on
                predefined conditions. Developed AWS Lambda functions to
                retrieve caller data from the CRM and provide it to agents in
                real-time, enhancing customer support.
              </p>
              <div className="techStack">
                <span>Amazon Connect</span>
                <span>AWS Lambda</span>
                <span>API Gateway</span>
              </div>
            </div>
          </div>
        </div>

        <div className="experienceItem">
          <div className="experienceHeader">
            <h4>Freelance Full Stack Developer</h4>
            <span className="date">2022 - Present</span>
          </div>
          <div className="experienceContent">
            <div className="project">
              <h5>Website Redesign & UX Optimization</h5>
              <p>
                Redesigned and optimized a website to improve user experience
                and site performance. Focused on enhancing navigation, visual
                consistency, and overall usability to ensure a seamless browsing
                experience.
              </p>
              <div className="techStack">
                <span>React</span>
                <span>Typescript</span>
                <span>TailwindCSS</span>
                <span>Material UI</span>
              </div>
            </div>

            <div className="project">
              <h5>Firewood Shop E-Commerce Platform</h5>
              <p>
                Developed a customized e-commerce platform for a local firewood
                shop, integrating Stripe for secure online payments. Implemented
                product categorization, a user-friendly interface, and an admin
                panel for seamless order and inventory management, enhancing
                customer convenience and business efficiency.
              </p>
              <div className="techStack">
                <span>React</span>
                <span>Material UI</span>
                <span>Node.js</span>
                <span>Express</span>
                <span>Stripe</span>
              </div>
            </div>

            <div className="project">
              <h5>Full E-Commerce Platform with Admin Panel</h5>
              <p>
                Developed a feature-rich e-commerce platform with comprehensive
                product and order management capabilities. Implemented a secure
                checkout system and enhanced user experience through intuitive
                navigation and product organization.
              </p>
              <div className="techStack">
                <span>React</span>
                <span>TailwindCSS</span>
                <span>Node.js</span>
                <span>Express</span>
                <span>MongoDB</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
