import React from "react";
import github from "../images/github.svg";
import linkedin from "../images/linkedin.svg";
import hacker from "../images/technologies/hackerrank.svg";
import mark from "../images/double-quote-serif-left-svgrepo-com.svg";

const About = () => {
  return (
    <div className="about container" id="about">
      <div className="leftSide">
        <h3 className="sectionTitle">About me</h3>

        <h4>
          <img src={mark} alt="double quote" className="mark" />
          <span>Passionate.</span>
          <span>Experienced.</span>
          <span>Creative.</span>
        </h4>
        <div className="iconSocail">
          <a href="https://github.com/youssefouba" target="_blank" rel="noopener noreferrer">
            <img src={github} alt="github" />
          </a>
          <a href="https://www.linkedin.com/in/youssef-oubaida/" target="_blank" rel="noopener noreferrer">
            <img src={linkedin} alt="linkedin" />
          </a>
          <a href="https://www.hackerrank.com/youssefoubaida11?hr_r=1" target="_blank" rel="noopener noreferrer">
            <img src={hacker} alt="hackerrank"/>
          </a>
        </div>
      </div>
      <div className="rigthSide">
        <p>
          Hello! My name is Youssef Oubaida and I'm a Full Stack Developer with AWS certification,
          working in web development for over 3 years. I specialize in building robust, scalable
          applications with modern tech stacks including React, Next.js, Node.js, and AWS services.
          I'm passionate about creating intuitive, user-friendly solutions that solve real-world problems.
        </p>
        <p className="focus">
          If you are a business seeking a web presence or an employer looking to
          hire, feel free to get in touch.
        </p>
        <div className="rigthBoxs">
          <div>
            <span>Completed</span>
            <p>20+ Projects</p>
          </div>
          <div>
            <span>Experience</span>
            <p>3+ Years</p>
          </div>
          <div>
            <span>Languages</span>
            <p>English - Arabic - French</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;