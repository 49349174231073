import React, { useState } from "react";
import email from "../images/mail.svg";
import phone from "../images/phone.svg";
import github from "../images/github.svg";
import linkedin from "../images/linkedin.svg";
import hackerrank from "../images/technologies/hackerrank.svg";
import { useForm } from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup"
import * as yup from "yup"
import {collection,addDoc ,serverTimestamp} from "firebase/firestore";
import { db } from "../Firebase-Config";
import Notifcation from "./Notifcation";

const Contact = () => {
  
  const schema=yup.object().shape({
    Name:yup.string().required(),
    Email:yup.string().email().required(),
    Subject:yup.string().required(),
    Message:yup.string().min(25).required()
  })
  const [notif,setnotif]=useState(false)
  const {register,handleSubmit,formState:{errors}}=useForm({resolver:yupResolver(schema)});

  const contactCollection=collection(db,"Contact");
  const onSubmit=(data)=>{
    data.date=serverTimestamp();
    const senddata= async()=>{
       await addDoc(contactCollection,data)
      setnotif(true);
       setTimeout(()=>{
        setnotif(false)
       },5000)
    }
    senddata();
  }

  return (
    <div className="contact container" id="contact">
      <h3 className="sectionTitle">Let's work together</h3>
      <div className="conatactTypes">
        <div className="directContact">
          <div className="title">
            <h4>Have a project ?</h4>
            <h4>Let's make great things !</h4>
          </div>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <input
              type="text"
              placeholder="Name"
              className={!errors.Name ? "name" : "name erorr"} {...register("Name")}
            />
            <input
              type="text"
              placeholder="Email"
              className={!errors.Email ? "email" : "email erorr"} {...register("Email")}
            />
            <input
              type="text"
              placeholder="Subject"
              className={!errors.Subject ? "subject" : "subject erorr"} {...register("Subject")}
            />
            <textarea
              className={!errors.Message ? "message" : "message erorr"} {...register("Message")}
              name="Message"
              placeholder="Message"
            ></textarea>
            <button type="submit">Send Message</button>
          </form>
        </div>
        <div className="otherContact">
          <h5>More information :</h5>
          <p>
            <img src={email} alt="email icone" /> mr.oubaidayoussef@gmail.com
          </p>
          <p>
            <img src={phone} alt="" /> +212620964403
          </p>
          <h5 className="mediatitle">Other Contacts :</h5>
          <div className="socilaMedia">
            <a href="https://github.com/youssefouba" target="_blank">
              <img src={github} alt="github" />
            </a>
            <a href="https://www.linkedin.com/in/youssef-oubaida/" target="_blank">
              <img src={linkedin} alt="linkedin" />
            </a>
            <a href="https://www.hackerrank.com/certificates/28dc6269ea50" target="_blank">
              <img src={hackerrank} alt="hackerrank" />
            </a>
          </div>
        </div>
      </div>
      {notif && <Notifcation/> }
    </div>
  );
};

export default Contact;
